
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUILoadingInsideAContainer from "@/views/resources/documentation/element-ui/notice/loading/LoadingInsideAContainer.vue";
import EUICustomization from "@/views/resources/documentation/element-ui/notice/loading/Customization.vue";
import EUIFullScreenLoading from "@/views/resources/documentation/element-ui/notice/loading/FullScreenLoading.vue";
import EUIService from "@/views/resources/documentation/element-ui/notice/loading/Service.vue";

export default defineComponent({
  name: "loading",
  components: {
    EUILoadingInsideAContainer,
    EUICustomization,
    EUIFullScreenLoading,
    EUIService
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Loading");
    });
  }
});
